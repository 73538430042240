<template>
  <wish-form
    v-model="wish"
    :roomId="id"
    @submit="onSubmit"
    @cancel="onCancel"
  ></wish-form>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Room, Wish } from '@/types/wishlist'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { wishConverter } from '@/converter/wishlistConverter'
import WishForm from '@/components/wish/WishForm.vue'
import { updateAmount } from '@/firestore/roomsRepository'

export default defineComponent({
  components: { WishForm },
  setup() {
    const db = firebase.firestore()
    const route = useRoute()
    const router = useRouter()

    const { id } = route.params

    const wish: Wish = reactive({
      name: '',
      price: null,
      state: 'wish',
    })

    const onSubmit = async () => {
      await db
        .collection('rooms')
        .doc(id as string)
        .collection('wishes')
        .withConverter(wishConverter)
        .doc()
        .set(wish)

      await updateAmount(id as string)

      router.push({ name: 'WishListShow', params: { id: id } })
    }

    const onCancel = () => {
      router.push({ name: 'WishListShow', params: { id: id } })
    }

    return {
      id,
      wish,
      onSubmit,
      onCancel,
    }
  },
})
</script>
